<template>
  <div class="dashboard">
    <div class="row mb-4">
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Orders
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h5 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.orders.total }}
                </h5>
                <router-link to="/orders" class="small text-decoration-underline"
                  >View orders</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-primary rounded fs-3">
                  <i class="ri-sort-desc text-primary"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Products
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.products}}
                </h4>
                <router-link to="/products" class="small text-decoration-underline" >See products</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-warning rounded fs-3">
                  <i class="ri-luggage-cart-line text-warning"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p  class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                  Buyers
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.buyer}}
                </h4>
                <router-link to="/users?role=buyer" class="small text-decoration-underline"
                  >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded fs-3">
                  <i class="ri ri-team-line text-info"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                  Revenue
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.revenue}}
                </h4>
                <router-link to="/payments" class="text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded fs-3">
                  <i class="bx bx-dollar-circle text-success"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <b-card no-body class="card-animate overflow-hidden">
            <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                    height="120">
                    <path id="Shape 8" class="s0"
                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                </svg>
            </div>
            <b-card-body class="p-3" style="z-index:1 ;">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                        <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Draft Orders</p>
                        <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                            <count-to :startVal="0" :endVal="result.orders.draft" :duration="5000"></count-to>
                        </h4>
                    </div>
                    <div class="flex-shrink-0">

                        <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.orders.draft)]'
                            :options="{ ...chartOptions, ...chartcolors }"></apexchart>
                    </div>
                </div>
            </b-card-body>
        </b-card>
      </div>
      <div class="col-md-6">
          <b-card no-body class="card-animate overflow-hidden">
              <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                      height="120">
                      <path id="Shape 8" class="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                  </svg>
              </div>
              <b-card-body class="p-3" style="z-index:1 ;">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1 overflow-hidden">
                          <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Pending Orders</p>
                          <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                              <count-to :startVal="0" :endVal="result.orders.pending" :duration="5000"></count-to>
                          </h4>
                      </div>
                      <div class="flex-shrink-0">
                          <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.orders.pending)]'
                              :options="{ ...chartOptions }"></apexchart>
                      </div>
                  </div>
              </b-card-body>
          </b-card>
      </div>
      <div class="col-md-6">
          <b-card no-body class="card-animate overflow-hidden">
              <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                      height="120">
                      <path id="Shape 8" class="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                  </svg>
              </div>
              <b-card-body class="p-3" style="z-index:1 ;">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1 overflow-hidden">
                          <p class="text-uppercase fw-medium text-muted text-truncate mb-3">Delivered</p>
                          <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                              <count-to :startVal="0" :endVal="result.orders.delivered" :duration="5000"></count-to>
                          </h4>
                      </div>
                      <div class="flex-shrink-0">
                          <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.orders.delivered)]'
                              :options="{ ...chartOptions }"></apexchart>
                      </div>
                  </div>
              </b-card-body>
          </b-card>
      </div>
      <div class="col-md-6">
          <b-card no-body class="card-animate overflow-hidden">
              <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                      height="120">
                      <path id="Shape 8" class="s0"
                          d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                  </svg>
              </div>
              <b-card-body class="p-3" style="z-index:1 ;">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1 overflow-hidden">
                          <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Completed</p>
                          <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                              <count-to :startVal="0" :endVal="result.orders.completed" :duration="5000"></count-to>
                          </h4>
                      </div>
                      <div class="flex-shrink-0">
                          <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.orders.completed)]'
                              :options="{ ...chartOptions }"></apexchart>
                      </div>
                  </div>
              </b-card-body>
          </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { CountTo } from "vue3-count-to";
import VueApexCharts from "vue3-apexcharts";

export default {
    name: 'dashboard',
    components:{
      apexchart: VueApexCharts,
      CountTo,
    },
    data(){
        return{
          result: {
            "orders": {
              "total": 0,
              "draft": 0,
              "pending": 0,
              "delivered": 0,
              "completed": 0,
            },
            "users": {
               "total": 0,
               "buyer": 0,
               "admin": 0
            },
            "revenue": 0,
            "products": 0
          },
          chartcolors: {
            colors: ["#f06548"]
          },
          chartOptions: {
            chart: {
              type: "radialBar",
              width: 105,
              sparkline: {
                enabled: true,
              }
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '70%'
                },
                track: {
                  margin: 1
                },
                dataLabels: {
                  show: true,
                  name: {
                    show: false
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontWeight: 600,
                    offsetY: 8,
                  }
                }
              },
            },
            colors: ["#0ab39c"]
          }
        }
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      getPercent(){
        return (val) => Math.round((val * 100) / this.result.orders.total) || 0
      }
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.result = response.data.data;
        }
      })
    }
}
</script>

<style scoped>
.widget-pattern .s0 {
    opacity: .05;
    fill: var(--bs-success);
}
</style>
